import { mapGetters } from 'vuex'
import i18n from '../plugins/i18n'
import { en } from '../plugins/en'
import store from './../store/index'
import dayjs from 'dayjs'

export const authComputed = {
  ...mapGetters('user', ['loggedIn', 'username']),
  ...mapGetters('permission', [
    'sideBarLinks',
    'links',
    'MTPLDashboardTabs',
    'CMRDashboardTabs',
    'CIDashboardTabs',
    'CASCODashboardTabs',
    'MTPLInjuredDashboardTabs',
    'KRDNADashboardTabs',
    'CPDashboardTabs',
    'BCADashboardTabs',
    'userTabs',
    'vehicleTabs',
    'driverTabs',
    'insuredPersonTabs',
  ]),
  ...mapGetters('notification', ['unseen']),
}

export const findFileCategoryTranslation = (text) => {
  let fileCategories = en.fileCategories
  let fileCategoryKey = null
  for (const key in fileCategories) {
    if (fileCategories[key] === text) {
      fileCategoryKey = key
    }
  }
  if (fileCategoryKey) {
    return i18n.t(`fileCategories["${fileCategoryKey}"]`)
  } else {
    return text
  }
}

export const claimFiltersToParams = (filters) => {
  let queryParams = {}
  if (filters.selection?.length > 0) {
    if (
      filters.selection.includes('Open') &&
      !filters.selection.includes('Closed')
    ) {
      queryParams.isClosed = 0
    } else if (
      !filters.selection.includes('Open') &&
      filters.selection.includes('Closed')
    ) {
      queryParams.isClosed = 1
    }
    if (filters.selection.includes('2023')) {
      queryParams.reportedAfterDate = '2022-12-31T23:59:59Z'
      queryParams.reportedToDate = '2023-12-31T23:59:59Z'
    }
    if (filters.selection.includes('Followed')) {
      queryParams.followedBy = store.getters['user/user'].id
    }
    if (filters.selection.includes('New')) {
      queryParams.onlyNewByClients = 1
    }

    if (filters.selection.includes('Suspended')) {
      queryParams.isSuspended = 1
    }

    //CMR Specific params
    queryParams.cargoType = []
    if (filters.selection.includes('Standard Cargo')) {
      queryParams.cargoType.push('standard-cargo')
    }

    if (filters.selection.includes('Vehicle')) {
      queryParams.cargoType.push('vehicle')
    }

    if (filters.selection.includes('Marked')) {
      queryParams.marked = 1
    }

    if (filters.selection.includes('Claim To Third Party')) {
      queryParams.isClaimToThirdParty = 1
    }

    if (filters.selection.includes('Claim To Entrusted Property')) {
      queryParams.isClaimToEntrustedProperty = 1
    }

    if (filters.selection.includes('Freight Forwarding Claim')) {
      queryParams.isFreightForwardingClaim = 1
    }
  }

  if (filters.client && filters.client != '') {
    queryParams.clientIds = filters.client
  }
  if (filters.stage && filters.stage != '') {
    queryParams.stageIds = filters.stage
  }
  if (filters.toDate && filters.toDate != '') {
    queryParams.reportedToDate = dayjs(filters.toDate).toISOString()
  }
  if (filters.fromDate && filters.fromDate != '') {
    queryParams.reportedAfterDate = dayjs(filters.fromDate).toISOString()
  }
  //CMR specific filter parsing
  if (filters.vin?.length > 3) {
    queryParams.vin = filters.vin
  }

  queryParams.claimSearch = filters.claimSearch

  let queryParamsString = new URLSearchParams(queryParams).toString()
  if (Object.entries(queryParams).length > 0) {
    queryParamsString = '&' + queryParamsString
  }
  return queryParamsString
}

export const formatString = (str) => {
  // Split the string into words
  const words = str.split(' ')

  // Lowercase the first word
  const firstWord = words[0].toLowerCase()

  // Uppercase the remaining words and join them together
  const formattedWords = words
    .slice(1)
    .map((word) => {
      const firstLetter = word.charAt(0).toUpperCase()
      const restOfWord = word.slice(1).toLowerCase()
      return firstLetter + restOfWord
    })
    .join('')

  // Combine the first lowercase word with the uppercase formatted words
  const formattedString = `${firstWord}${formattedWords}`

  return formattedString.replace('‘', '')
}

export const translateArray = (array, prefix) => {
  let arrayToTranslate = array
  arrayToTranslate = arrayToTranslate.map((element) => {
    if (element instanceof Object) {
      if (i18n.te(`${prefix}["${formatString(element.value)}"]`)) {
        return {
          text: i18n.t(`${prefix}["${formatString(element.value)}"]`),
          value: element.value,
        }
      } else {
        return {
          text: element.text,
          value: element.value,
        }
      }
    } else {
      if (i18n.te(`${prefix}["${formatString(element)}"]`)) {
        return {
          text: i18n.t(`${prefix}["${formatString(element)}"]`),
          value: element,
        }
      } else {
        return {
          text: element,
          value: element,
        }
      }
    }
  })
  return arrayToTranslate
}

export const arraysAreEqual = (arr1, arr2) => {
  // Check if arrays have the same length
  if (arr1.length !== arr2.length) {
    return false
  }

  // Iterate through each element and compare
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }

  // If all elements are equal, return true
  return true
}
